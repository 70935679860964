import React from 'react'
import { graphql } from 'gatsby'

// Template
import HomeTemplate from '../templates/home'

function HomePage({ data }) {
  return (
    <HomeTemplate data={data} />
  )
}

export default HomePage

export const query = graphql`
	query NativeHomeTemplate {
		wordpressPage(
			wordpress_id: { eq: 14 }
		) {
			...HomeFrag
		}

		allWordpressWpCampagnes(filter: {wpml_current_locale: {eq: "nl_NL"}}) {
			totalCount
		}

		allWordpressWpMerken(filter: {wpml_current_locale: {eq: "nl_NL"}}) {
			...MerkenFrag
    }
	}
`
